import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule, DOCUMENT } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
  Component,
  ContentChildren,
  ElementRef,
  forwardRef,
  NgModule,
  QueryList,
  ViewChild,
  Input,
  OnInit,
  AfterViewChecked,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
  Renderer2,
  NgZone,
  Inject,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Calendar } from 'primeng/calendar';
import { RfButtonModule } from '../button/button';
import { RfPrimeTemplate, RfSharedModule } from '../common/shared';
import { MenuToggleDirective } from './menutoggle.directive';
import { CalendarInnerComponent } from './inner-calendar';
import moment from 'moment';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig, OverlayService } from 'primeng/api';

export const CALENDAR_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => Calendar),
  multi: true,
};

export interface LocaleSettings {
  firstDayOfWeek?: number;
  dayNames: string[];
  dayNamesShort: string[];
  dayNamesMin: string[];
  monthNames: string[];
  monthNamesShort: string[];
  today: string;
  clear: string;
  dateFormat?: string;
}

@Component({
  selector: 'rf-calendar',
  templateUrl: './calender.html',
  animations: [
    trigger('overlayAnimation', [
      state(
        'visible',
        style({
          transform: 'translateY(0)',
          opacity: 1,
        })
      ),
      state(
        'visibleTouchUI',
        style({
          transform: 'translate(-50%,-50%)',
          opacity: 1,
        })
      ),
      transition('void => visible', [
        style({ transform: 'translateY(5%)', opacity: 0 }),
        animate('{{showTransitionParams}}'),
      ]),
      transition('visible => void', [
        animate(
          '{{hideTransitionParams}}',
          style({
            opacity: 0,
            transform: 'translateY(5%)',
          })
        ),
      ]),
      transition('void => visibleTouchUI', [
        style({ opacity: 0, transform: 'translate3d(-50%, -40%, 0) scale(0.9)' }),
        animate('{{showTransitionParams}}'),
      ]),
      transition('visibleTouchUI => void', [
        animate(
          '{{hideTransitionParams}}',
          style({
            opacity: 0,
            transform: 'translate3d(-50%, -40%, 0) scale(0.9)',
          })
        ),
      ]),
    ]),
  ],
  host: {
    '[class.ui-inputwrapper-filled]': 'filled',
    '[class.ui-inputwrapper-focus]': 'focus',
  },
  providers: [CALENDAR_VALUE_ACCESSOR],
})
export class RfCalendarComponent extends Calendar implements OnInit, AfterViewChecked {
  @ContentChildren(RfPrimeTemplate) templates: QueryList<any>;
  @ViewChild('inputfield', { static: true }) inputfieldViewChild: ElementRef;
  @ViewChild('ref', { static: true }) refViewChild: ElementRef;
  @Output() hideDatePicker: EventEmitter<any> = new EventEmitter();
  @Input() timeSeparator = ':';
  @Input() primeCalendar = false;
  @Input() dateDropDown: any = [];
  @Input() toaster: any;
  @Input() allowMonthSelect: any = false;
  @Input() moment: any;
  @Input() currentCalendarDate: any;
  @Input() navigatorForMultipleMonth: boolean = false;
  @Input() pDateFormat: string = 'DD/MM/YYYY';
  customResult: any;
  selectedIndex = 3;
  activeCalendar = false;

  // tslint:disable-next-line:member-ordering
  timePicker: any = [
    {
      calendar1: {
        hour: 12,
        minute: '00',
        zone: 'am',
      },
      calendar2: {
        hour: 1,
        minute: '44',
        zone: 'am',
      },
    },
  ];
  hour: any = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  // tslint:disable-next-line:max-line-length
  minutes: any = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
    '32',
    '33',
    '34',
    '35',
    '36',
    '37',
    '38',
    '39',
    '40',
    '41',
    '42',
    '43',
    '44',
    '45',
    '46',
    '47',
    '48',
    '49',
    '50',
    '51',
    '52',
    '53',
    '56',
    '58',
    '59',
  ];
  rangeDateOption = false;
  selectDateRange: any;
  @Input() defaultSelectedDate: any;
  @Input() reset: boolean = false;
  @Input() identifier = 'MTD';
  @Output() onIdentifierChange: EventEmitter<any> = new EventEmitter();
  customRange: any;
  navigationObj: any = {
    monthNames: [],
    selectedFirstMonth: 'Jan',
    selectedYear: 2021,
    selectedMonth: 8,
    navigationBydropDown: false,
  };
  constructor(
    public el: ElementRef,
    public renderer: Renderer2,
    public cdr: ChangeDetectorRef,
    private _zone: NgZone,
    private _config: PrimeNGConfig,
    public overlayService: OverlayService,
    private translateService: TranslateService,
    @Inject(DOCUMENT) document: Document
  ) {
    super(document, el, renderer, cdr, _zone, _config, overlayService);
    if (this._locale) {
      this._locale.monthNamesShort = [
        this.translateService.instant('Calender_months.jan'),
        this.translateService.instant('Calender_months.feb'),
        this.translateService.instant('Calender_months.mar'),
        this.translateService.instant('Calender_months.apr'),
        this.translateService.instant('Calender_months.may'),
        this.translateService.instant('Calender_months.jun'),
        this.translateService.instant('Calender_months.jul'),
        this.translateService.instant('Calender_months.aug'),
        this.translateService.instant('Calender_months.sep'),
        this.translateService.instant('Calender_months.oct'),
        this.translateService.instant('Calender_months.nov'),
        this.translateService.instant('Calender_months.dec'),
      ];
      this._locale.monthNames = [
        this.translateService.instant('January'),
        this.translateService.instant('February'),
        this.translateService.instant('March'),
        this.translateService.instant('April'),
        this.translateService.instant('May'),
        this.translateService.instant('June'),
        this.translateService.instant('July'),
        this.translateService.instant('August'),
        this.translateService.instant('September'),
        this.translateService.instant('October'),
        this.translateService.instant('November'),
        this.translateService.instant('December'),
      ];
    }
  }
  toggleSettingMenu() {
    this.activeCalendar = !this.activeCalendar;
  }

  onDatePickerClick(e: any) {}
  selectCustomDate(params: any, index: any) {
    this.selectedIndex = index;
    this.activeCalendar = false;
    if (!params.key) {
      params.key = params.label;
    }
    if (
      params.key.toLowerCase() !== 'Custom Range'.toLowerCase() &&
      params.key.toLowerCase() != 'gamme personnalisée'
    ) {
      this.customRange = '';
      this.customResult = '';
      this.inputFieldValue = '';
      this.selectDateRange = params;
      this.defaultSelectedDate.label = params.label;
      this.defaultSelectedDate.key = params.key;
      this.hideDatePicker.emit({ type: 'dropDownRange', data: this.selectDateRange });
      // tslint:disable-next-line:max-line-length
      if (params.value[0] != undefined) {
        this.defaultSelectedDate.value = `${this.moment(params.value[0]._d).format(this.pDateFormat)}-${this.moment(
          params.value[1]._d
        ).format(this.pDateFormat)}`;
      }
    } else {
      if (this.navigatorForMultipleMonth) {
        this.navigationObj.selectedFirstMonth = this.navigationObj.monthNames[this.currentMonth];
        this.navigationObj.selectedYear = this.currentYear;
        this.navigationObj.selectedMonth = this.currentMonth;
      }
      this.customRange = params;
      setTimeout(() => {
        const custom_range = document.getElementById('custom_range');
        custom_range.click();
      }, 100);
    }
  }

  previousDate(value: any) {
    const date = `${value.year}-${value.month + 1}-${value.day}`;
    const selectedDate = Object.assign({}, this.selectDateRange);
    const startDate = this.moment(selectedDate.value[0]._d).format('YYYY-MM-D');
    const endDate = this.moment(selectedDate.value[1]._d).format('YYYY-MM-D');
    const range = this.moment(date).isBetween(startDate, endDate);
    if (range) {
      value.range = range;
    }
  }
  ngOnInit() {
    super.ngOnInit();
    this.selectDateRange = Object.assign({}, this.defaultSelectedDate);
    // tslint:disable-next-line:max-line-length
    this.defaultSelectedDate.value = `${this.moment(this.defaultSelectedDate.value[0]._d).format(
      this.pDateFormat
    )}-${this.moment(this.defaultSelectedDate.value[1]._d).format(this.pDateFormat)}`;
    if (this.navigatorForMultipleMonth) {
      this.initForNavigation();
    }
  }
  // navigation Start //
  navigatorForMonth(e: any) {
    this.navigationObj.selectedMonth = parseInt(e);
    this.createMonths(parseInt(e), this.navigationObj.selectedYear);
  }
  navigatorForYear(e: any) {
    this.navigationObj.selectedYear = parseInt(e);
    this.createMonths(this.navigationObj.selectedMonth, parseInt(e));
  }
  navigationBackward(event: any) {
    if (this.navigatorForMultipleMonth) {
      event.stopPropagation();
      let month = this.months[0].month == 0 ? 11 : this.months[0].month - 1;
      this.navigationObj.selectedFirstMonth = this.navigationObj.monthNames[month];
      this.navigationObj.selectedYear = this.months[0].year;
      this.navigationObj.selectedMonth = month;
      this.createMonths(month, this.navigationObj.selectedYear);
    } else {
      this.navBackward(event);
    }
  }
  navigationForward(event: any) {
    if (this.navigatorForMultipleMonth) {
      event.stopPropagation();
      let month = this.months[0].month == 11 ? 0 : this.months[0].month + 1;
      this.navigationObj.selectedFirstMonth = this.navigationObj.monthNames[month];
      this.navigationObj.selectedYear = this.months[0].year;
      this.navigationObj.selectedMonth = month;
      this.createMonths(month, this.navigationObj.selectedYear);
    } else {
      this.navForward(event);
    }
  }

  initForNavigation() {
    if (this.navigatorForMultipleMonth && this.yearRange) {
      const years = this.yearRange.split(':');
      const yearStart = parseInt(years[0]);
      const yearEnd = parseInt(years[1]);

      this.populateYearOptions(yearStart, yearEnd);
    }
    this.navigationObj.monthNames = [
      this.translateService.instant('Calender_months.jan'),
      this.translateService.instant('Calender_months.feb'),
      this.translateService.instant('Calender_months.mar'),
      this.translateService.instant('Calender_months.apr'),
      this.translateService.instant('Calender_months.may'),
      this.translateService.instant('Calender_months.jun'),
      this.translateService.instant('Calender_months.jul'),
      this.translateService.instant('Calender_months.aug'),
      this.translateService.instant('Calender_months.sep'),
      this.translateService.instant('Calender_months.oct'),
      this.translateService.instant('Calender_months.nov'),
      this.translateService.instant('Calender_months.dec'),
    ];
    this.navigationObj.selectedFirstMonth = this.navigationObj.monthNames[this.currentMonth];
  }
  // navigation End //
  ngOnChanges() {
    if (this.reset) {
      this.customRange = '';
      this.customResult = '';
      this.inputFieldValue = '';
      this.selectedIndex = 3;
      this.selectDateRange = Object.assign({}, this.defaultSelectedDate);
      // tslint:disable-next-line:max-line-length
      this.defaultSelectedDate.value = `${this.moment(this.defaultSelectedDate.value[0]._d).format(
        this.pDateFormat
      )}-${this.moment(this.defaultSelectedDate.value[1]._d).format(this.pDateFormat)}`;
    }
  }
  cancel() {
    this.hideDatePicker.emit(false);
  }
  apply() {
    // tslint:disable-next-line:max-line-length
    const one = `${this.inputFieldValue.split('-')[0]}${this.timePicker[0].calendar1.hour}:${
      this.timePicker[0].calendar1.minute
    } ${this.timePicker[0].calendar1.zone}`;
    // tslint:disable-next-line:max-line-length
    const two = `${this.inputFieldValue.split('-')[1]} ${this.timePicker[0].calendar2.hour}:${
      this.timePicker[0].calendar2.minute
    } ${this.timePicker[0].calendar2.zone}`;
    const isDateValid = `${one} - ${two}`.search('undefined');
    if (isDateValid !== -1) {
      this.toaster.info('Please select end date');
      return;
    }
    const CurrentDate = new Date();
    const startGivenDate = new Date(one);
    const endGivenDate = new Date(two);

    if (startGivenDate > CurrentDate) {
      this.toaster.info('Start date is greater than the current date.');
      return;
    }
    if (endGivenDate > CurrentDate) {
      this.toaster.info('End date is greater than the current date.');
      return;
    }
    this.customResult = `${one} - ${two}`;
    this.customResult = `${this.moment(one).format(this.pDateFormat)}-${this.moment(two).format(this.pDateFormat)}`;
    // tslint:disable-next-line:max-line-length
    this.hideDatePicker.emit({
      type: 'customRange',
      startDate: new Date(one),
      endDate: new Date(two),
      range: this.customResult,
    });
  }
  selectDropDown(type: any, data: any, i: any) {
    if (i === 0) {
      if (type === 'hour') {
        this.timePicker[0].calendar1.hour = data;
      }
      if (type === 'minute') {
        this.timePicker[0].calendar1.minute = data;
      }
      if (type === 'zone') {
        this.timePicker[0].calendar1.zone = data;
      }
    }
    if (i === 1) {
      if (type === 'hour') {
        this.timePicker[0].calendar2.hour = data;
      }
      if (type === 'minute') {
        this.timePicker[0].calendar2.minute = data;
      }
      if (type === 'zone') {
        this.timePicker[0].calendar2.zone = data;
      }
    }
  }

  calendarMenu(event: Object) {
    if (event && event['value'] === true) {
      this.activeCalendar = false;
    }
    // if(this.customRange && this.customRange.label == 'Custom Range'){
    //   this.hideDatePicker.emit(false);
    // }
  }

  ngAfterViewChecked() {
    // your code to update the model
    this.cdr.detectChanges();
  }
}

// ************************* CVP CALENDAR **********************************************************************//

@Component({
  selector: 'cvp-calendar',
  templateUrl: './calender-clearview.html',
  animations: [
    trigger('overlayAnimation', [
      state(
        'visible',
        style({
          transform: 'translateY(0)',
          opacity: 1,
        })
      ),
      state(
        'visibleTouchUI',
        style({
          transform: 'translate(-50%,-50%)',
          opacity: 1,
        })
      ),
      transition('void => visible', [
        style({ transform: 'translateY(5%)', opacity: 0 }),
        animate('{{showTransitionParams}}'),
      ]),
      transition('visible => void', [
        animate(
          '{{hideTransitionParams}}',
          style({
            opacity: 0,
            transform: 'translateY(5%)',
          })
        ),
      ]),
      transition('void => visibleTouchUI', [
        style({ opacity: 0, transform: 'translate3d(-50%, -40%, 0) scale(0.9)' }),
        animate('{{showTransitionParams}}'),
      ]),
      transition('visibleTouchUI => void', [
        animate(
          '{{hideTransitionParams}}',
          style({
            opacity: 0,
            transform: 'translate3d(-50%, -40%, 0) scale(0.9)',
          })
        ),
      ]),
    ]),
  ],
  host: {
    '[class.ui-inputwrapper-filled]': 'filled',
    '[class.ui-inputwrapper-focus]': 'focus',
  },
  providers: [CALENDAR_VALUE_ACCESSOR],
})
export class CVPCalendarComponent extends Calendar implements OnInit, AfterViewChecked, OnChanges {
  @ContentChildren(RfPrimeTemplate) templates: QueryList<any>;
  @ViewChild('inputfield', { static: true }) inputfieldViewChild: ElementRef;
  @ViewChild('ref', { static: true }) refViewChild: ElementRef;
  @ViewChild('dateDone', { static: true }) dateDone: ElementRef<HTMLElement>;
  @Output() hideDatePicker: EventEmitter<any> = new EventEmitter();
  @Output() onDataPickerOpen: EventEmitter<any> = new EventEmitter();
  @Output() _onDateRangeChange: EventEmitter<any> = new EventEmitter();
  @Output() _onMonthSelect: EventEmitter<any> = new EventEmitter();
  @Output() done: EventEmitter<any> = new EventEmitter();
  @Input() timeSeparator = ':';
  @Input() primeCalendar = false;
  @Input() dateDropDown: any = [];
  @Input() toaster: any;
  @Input() moment: any;
  @Input() showDonebutton = true;
  @Input() currentCalendarDate: any;
  @Input() allowMonthSelect = false;
  @Input() programDate: any = new Date();
  @Input() showDatePicker = false;
  @Input() identifier = 'MTD';
  @Output() onIdentifierChange: EventEmitter<any> = new EventEmitter();
  startYears = '2010:2019';
  endYears = '2010:2019';
  monthYear = {
    start: moment(new Date()).add(-1, 'month').toDate(),
    end: moment(new Date()).toDate(),
  };
  monthDisabledState = [
    { name: 'January', disabled: false },
    { name: 'February', disabled: false },
    { name: 'March', disabled: false },
    { name: 'April', disabled: false },
    { name: 'May', disabled: false },
    { name: 'June', disabled: false },
    { name: 'July', disabled: false },
    { name: 'August', disabled: false },
    { name: 'September', disabled: false },
    { name: 'October', disabled: false },
    { name: 'November', disabled: false },
    { name: 'December', disabled: false },
  ];
  selectedFilter = '';
  customResult: any;
  selectedIndex = 3;
  @Input() dateFormat = 'MMM D, YYYY';
  activeCalendar = false;
  // value: any = new Date();

  // tslint:disable-next-line:member-ordering
  timePicker: any = [
    {
      calendar1: {
        hour: 12,
        minute: '00',
        zone: 'am',
      },
      calendar2: {
        hour: 1,
        minute: '44',
        zone: 'am',
      },
    },
  ];
  hour: any = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  // tslint:disable-next-line:max-line-length
  minutes: any = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
    '32',
    '33',
    '34',
    '35',
    '36',
    '37',
    '38',
    '39',
    '40',
    '41',
    '42',
    '43',
    '44',
    '45',
    '46',
    '47',
    '48',
    '49',
    '50',
    '51',
    '52',
    '53',
    '56',
    '58',
    '59',
  ];
  rangeDateOption = false;
  selectedMonth: any;
  selectDateRange: any;
  params: any;
  @Input() defaultSelectedDate: any;
  customRange: any;
  rangeLabel: string;
  dateRanges: any[] = [];
  doneButton = 'Done';

  constructor(
    public el: ElementRef,
    public renderer: Renderer2,
    public cdr: ChangeDetectorRef,
    private _zone: NgZone,
    private _config: PrimeNGConfig,
    public overlayService: OverlayService,
    private translateService: TranslateService,
    @Inject(DOCUMENT) document: Document
  ) {
    super(document, el, renderer, cdr, _zone, _config, overlayService);
    this.dateRanges[0] = new Date().toISOString();
    this.dateRanges[1] = new Date().toISOString();
    this.startYears = '2010:' + new Date().getFullYear();
    this.endYears = '2010:' + new Date().getFullYear();
    if (this._locale) {
      this._locale.monthNamesShort = [
        this.translateService.instant('Calender_months.jan'),
        this.translateService.instant('Calender_months.feb'),
        this.translateService.instant('Calender_months.mar'),
        this.translateService.instant('Calender_months.apr'),
        this.translateService.instant('Calender_months.may'),
        this.translateService.instant('Calender_months.jun'),
        this.translateService.instant('Calender_months.jul'),
        this.translateService.instant('Calender_months.aug'),
        this.translateService.instant('Calender_months.sep'),
        this.translateService.instant('Calender_months.oct'),
        this.translateService.instant('Calender_months.nov'),
        this.translateService.instant('Calender_months.dec'),
      ];
    }
    if (this._locale) {
      this._locale.monthNames = [
        this.translateService.instant('January'),
        this.translateService.instant('February'),
        this.translateService.instant('March'),
        this.translateService.instant('April'),
        this.translateService.instant('May'),
        this.translateService.instant('June'),
        this.translateService.instant('July'),
        this.translateService.instant('August'),
        this.translateService.instant('September'),
        this.translateService.instant('October'),
        this.translateService.instant('November'),
        this.translateService.instant('December'),
      ];
    }
  }
  onDone(event: any) {
    let date: any = ' to ';
    let start = this.moment(new Date());
    let end = this.moment(new Date());
    if (this.defaultSelectedDate.value !== undefined) {
      date = this.defaultSelectedDate.value.split('to');
      if (date.length > 1) {
        const d1 = date[0].trim();
        const d2 = date[1].trim();
        start = this.moment(d1, this.dateFormat).toDate() || new Date();
        end = this.moment(d2, this.dateFormat).toDate() || new Date();
      }
    } else if (this.defaultSelectedDate.value.length > 0) {
      date = this.defaultSelectedDate.value;
      if (date.length > 1) {
        start = this.moment(date[0].trim(), this.dateFormat).toDate() || new Date();
        end = this.moment(date[1].trim(), this.dateFormat).toDate() || new Date();
      }
    }
    const emit = [this.moment(start).format(this.dateFormat), this.moment(end).format(this.dateFormat)];
    this.done.emit({ e: event, date: emit, close: true, period: this.selectedFilter });
  }
  setMonthDisabled(disabled: boolean, except?: Array<string>) {
    this.monthDisabledState.forEach((month: { name: string; disabled: boolean }) => {
      if (except.indexOf(month.name) === -1) {
        month.disabled = disabled;
      }
    });
  }
  toggleSettingMenu() {
    this.activeCalendar = !this.activeCalendar;
  }

  isCustomRange() {
    if (
      this.rangeLabel?.toUpperCase() === 'CUSTOM RANGE' ||
      this.selectedFilter?.toUpperCase() === 'CUSTOM RANGE' ||
      this.identifier?.toUpperCase() === 'CUSTOM RANGE'
    ) {
      return true;
    }
    return false;
  }

  onInnerCalendarChange(startEnd: { start: Date; end: Date }) {
    if (startEnd.start === undefined || startEnd.end === undefined) {
      return;
    }
    const start = startEnd.start || new Date();
    const end = startEnd.end || new Date();
    this.monthYear.start = start;
    this.monthYear.end = end;
    this.defaultSelectedDate.value = `${this.moment(start).format(this.dateFormat)}
    to ${this.moment(end).format(this.dateFormat)}`;
    this.onDateRangeChangeEmiter([
      this.moment(start).format(this.dateFormat),
      this.moment(end).format(this.dateFormat),
    ]);
  }
  defaultOnFilterSelect(params: any) {
    this.customRange = '';
    this.customResult = '';
    this.inputFieldValue = '';
    this.numberOfMonths = 1;
    this.view = 'month';
    this.selectionMode = 'single';
    // const value = params.value(this.selectionMode === 'single' ? this.value : this.value[0]);
    // this.selectDateRange = {value: value};
    this.defaultSelectedDate.key = params.key;
    this.onIdentifierChange.emit(this.selectedFilter);
    this.updateUI();
  }

  setCalenderTranslation(date: any): any {
    if (date === undefined || date === null) {
      return date;
    }
    const dateArray = date.split('to');
    const fromDate = dateArray[0].trim().split(' ');
    const toDate = dateArray[1].trim().split(' ');

    fromDate[0] = this.translateService.instant('Calender_months.' + fromDate[0].trim().toLowerCase());
    toDate[0] = this.translateService.instant('Calender_months.' + toDate[0].trim().toLowerCase());

    let finalString = '';

    for (let i = 0; i < fromDate.length; i++) {
      finalString = finalString + ' ' + this.translateService.instant(fromDate[i].trim());
    }
    finalString = finalString + ' ' + this.translateService.instant('to') + ' ';
    for (let i = 0; i < toDate.length; i++) {
      finalString = finalString + ' ' + this.translateService.instant(toDate[i]);
    }
    return finalString;
  }
  showYearChangeBar() {
    let valid = false;
    const filter = this.selectedFilter.toUpperCase();
    // tslint:disable-next-line:max-line-length
    valid =
      filter === 'Program to Date'.toUpperCase() ||
      filter === 'Previous Year'.toUpperCase() ||
      filter === 'Previous Quarter'.toUpperCase() ||
      filter === 'Previous Month'.toUpperCase();

    // this.selectedFilter === 'R10' ||
    // this.selectedFilter === 'R1' ||
    // this.selectedFilter === 'R30' ||
    // this.selectedFilter === 'MTD';
    return valid;
  }

  close(e: any) {
    e.preventDefault();
    e.stopPropagation();
    this.done.emit('close');
  }

  selectCustomDate(params: any, index: any) {
    this.selectedIndex = index;
    this.activeCalendar = false;
    this.params = params;
    this.rangeLabel = params.key;
    localStorage.setItem('date_identifier', params.key);
    let toDate = moment();

    switch (params.key.toLocaleUpperCase()) {
      case 'R10':
        this.selectedFilter = 'R10';
        toDate = this.moment();
        this.selectedMonth = toDate.get('month');
        this.setMonthDisabled(true, [toDate.format('MMMM')]);

        this.defaultOnFilterSelect(params);
        this.defaultSelectedDate.value = `${this.moment().subtract(10, 'days').format(this.dateFormat)}
        to ${toDate.format(this.dateFormat)}`;
        this.onDateRangeChangeEmiter([
          this.moment().subtract(10, 'days').format(this.dateFormat),
          toDate.format(this.dateFormat),
        ]);
        this.currentYear = toDate.get('year');
        break;
      case 'R1':
        this.selectedFilter = 'R1';
        toDate = this.moment();

        this.selectedMonth = toDate.get('month');
        this.setMonthDisabled(true, [toDate.format('MMMM')]);

        this.defaultOnFilterSelect(params);
        this.defaultSelectedDate.value = `${this.moment().subtract(1, 'days').format(this.dateFormat)}
          to ${toDate.format(this.dateFormat)}`;
        this.onDateRangeChangeEmiter([
          this.moment().subtract(1, 'days').format(this.dateFormat),
          toDate.format(this.dateFormat),
        ]);
        this.currentYear = toDate.get('year');
        break;
      case 'R30':
        this.selectedFilter = 'R30';
        toDate = this.moment();

        this.selectedMonth = toDate.get('month');
        this.setMonthDisabled(true, [toDate.format('MMMM')]);

        this.defaultOnFilterSelect(params);
        this.defaultSelectedDate.value = `${this.moment().subtract(30, 'days').format(this.dateFormat)}
        to ${this.moment().format(this.dateFormat)}`;
        this.onDateRangeChangeEmiter([
          this.moment().subtract(30, 'days').format(this.dateFormat),
          toDate.format(this.dateFormat),
        ]);
        this.currentYear = toDate.get('year');
        break;
      case 'R90':
        this.selectedFilter = 'R90';
        toDate = this.moment();

        this.selectedMonth = toDate.get('month');
        this.setMonthDisabled(true, [toDate.format('MMMM')]);

        this.defaultOnFilterSelect(params);
        this.defaultSelectedDate.value = `${this.moment()
          .subtract(90, 'days')
          .format(this.dateFormat)}to ${this.moment().format(this.dateFormat)}`;
        this.onDateRangeChangeEmiter([
          this.moment().subtract(90, 'days').format(this.dateFormat),
          toDate.format(this.dateFormat),
        ]);
        this.currentYear = toDate.get('year');
        break;
      case 'MTD':
        this.selectedFilter = 'MTD';
        toDate = this.moment();

        this.selectedMonth = toDate.get('month');
        this.setMonthDisabled(true, [toDate.format('MMMM')]);

        this.setMonthDisabled(true, []);
        this.defaultOnFilterSelect(params);
        this.defaultSelectedDate.value = `${this.moment().startOf('month').format(this.dateFormat)}
        to ${toDate.format(this.dateFormat)}`;
        this.onDateRangeChangeEmiter([
          this.moment().startOf('month').format(this.dateFormat),
          toDate.format(this.dateFormat),
        ]);
        this.currentYear = toDate.get('year');
        break;
      case '3MTD':
        this.selectedFilter = '3MTD';
        toDate = this.moment();
        this.selectedMonth = toDate.get('month');
        this.setMonthDisabled(true, [toDate.format('MMMM')]);
        this.defaultOnFilterSelect(params);
        this.defaultSelectedDate.value = `${this.moment()
          .startOf('month')
          .subtract(2, 'months')
          .format(this.dateFormat)} to ${this.moment().format(this.dateFormat)}`;
        this.onDateRangeChangeEmiter([
          moment().startOf('month').subtract(2, 'month').format(this.dateFormat),
          toDate.format(this.dateFormat),
        ]);
        this.currentYear = toDate.get('year');
        break;
      case '6MTD':
        this.selectedFilter = '6MTD';
        toDate = this.moment();
        this.selectedMonth = toDate.get('month');
        this.setMonthDisabled(true, [toDate.format('MMMM')]);
        this.defaultOnFilterSelect(params);
        this.defaultSelectedDate.value = `${this.moment()
          .startOf('month')
          .subtract(5, 'months')
          .format(this.dateFormat)} to ${this.moment().format(this.dateFormat)}`;
        this.onDateRangeChangeEmiter([
          moment().startOf('month').subtract(5, 'month').format(this.dateFormat),
          toDate.format(this.dateFormat),
        ]);
        this.currentYear = toDate.get('year');
        break;
      case '12MTD':
        this.selectedFilter = '12MTD';
        toDate = this.moment();
        this.selectedMonth = toDate.get('month');
        this.setMonthDisabled(true, [toDate.format('MMMM')]);
        this.defaultOnFilterSelect(params);
        this.defaultSelectedDate.value = `${this.moment()
          .startOf('month')
          .subtract(11, 'months')
          .format(this.dateFormat)} to ${this.moment().format(this.dateFormat)}`;
        this.onDateRangeChangeEmiter([
          moment().startOf('month').subtract(11, 'month').format(this.dateFormat),
          toDate.format(this.dateFormat),
        ]);
        this.currentYear = toDate.get('year');
        break;
      case 'QTD':
        this.selectedFilter = 'QTD';
        toDate = this.moment();

        this.selectedMonth = toDate.get('month');
        this.setMonthDisabled(false, []);

        this.defaultOnFilterSelect(params);
        this.defaultSelectedDate.value = `${this.moment()
          .quarter(this.moment().quarter())
          .startOf('quarter')
          .format(this.dateFormat)}
        to ${toDate.format(this.dateFormat)}`;
        this.onDateRangeChangeEmiter([
          this.moment().quarter(this.moment().quarter()).startOf('quarter').format(this.dateFormat),
          toDate.format(this.dateFormat),
        ]);
        this.currentYear = toDate.get('year');
        break;
      case 'YTD':
        this.selectedFilter = 'YTD';
        toDate = this.moment();

        this.selectedMonth = toDate.get('month');
        this.setMonthDisabled(true, [toDate.format('MMMM')]);

        this.defaultOnFilterSelect(params);
        this.defaultSelectedDate.value = `${this.moment().startOf('year').format(this.dateFormat)}
        to ${toDate.format(this.dateFormat)}`;
        this.onDateRangeChangeEmiter([
          this.moment().startOf('year').format(this.dateFormat),
          toDate.format(this.dateFormat),
        ]);
        this.currentYear = toDate.get('year');
        break;
      case 'FYTD':
        this.selectedFilter = 'FYTD';
        toDate = this.moment();

        this.selectedMonth = toDate.get('month');
        this.setMonthDisabled(true, [toDate.format('MMMM')]);
        this.defaultOnFilterSelect(params);

        let _from =
          moment([this.currentYear, index]).startOf('year').add(3, 'month') < moment()
            ? this.moment([this.currentYear, index]).startOf('year').add(3, 'month').format(this.dateFormat)
            : this.moment([this.currentYear, index])
                .startOf('year')
                .subtract(1, 'year')
                .add(3, 'month')
                .format(this.dateFormat);

        this.defaultSelectedDate.value = `${_from}
        to ${toDate.format(this.dateFormat)}`;

        this.onDateRangeChangeEmiter([_from, toDate.format(this.dateFormat)]);
        this.currentYear = toDate.get('year');
        break;
      case 'M36':
        this.selectedFilter = 'M36';
        toDate = this.moment();

        this.selectedMonth = toDate.get('month');
        this.setMonthDisabled(true, [toDate.format('MMMM')]);

        this.defaultOnFilterSelect(params);
        let date = new Date();
        date.setDate(date.getDate() + 1);
        this.defaultSelectedDate.value = `${this.moment(date).add(-36, 'month').format(this.dateFormat)}
          to ${toDate.format(this.dateFormat)}`;

        this.onDateRangeChangeEmiter([
          this.moment(new Date()).add(-36, 'month').format(this.dateFormat),
          toDate.format(this.dateFormat),
        ]);
        this.currentYear = toDate.get('year');
        break;
      case 'PREVIOUS MONTH':
        this.selectedFilter = 'PREVIOUS MONTH';
        toDate = this.moment(new Date()).add(-1, 'month').endOf('month');

        this.selectedMonth = toDate.get('month');
        this.setMonthDisabled(true, [toDate.format('MMMM')]);

        this.defaultOnFilterSelect(params);
        // this.defaultSelectedDate.value = `${this.moment().subtract(1, 'month').format(this.dateFormat)}
        // to ${this.moment().format(this.dateFormat)}`;
        // this.onDateRangeChangeEmiter([
        //   this.moment().subtract(1, 'month').format(this.dateFormat),
        //   this.moment().format(this.dateFormat)
        // ]);
        // tslint:disable-next-line:max-line-length
        this.defaultSelectedDate.value = `${this.moment(new Date())
          .add(-1, 'month')
          .startOf('month')
          .format(this.dateFormat)}
        to ${toDate.format(this.dateFormat)}`;

        this.onDateRangeChangeEmiter([
          this.moment(new Date()).add(-1, 'month').startOf('month').format(this.dateFormat),
          toDate.format(this.dateFormat),
        ]);
        this.currentYear = toDate.get('year');
        break;
      case 'PREVIOUS QUARTER':
        this.selectedFilter = 'PREVIOUS QUARTER';
        toDate = this.moment().subtract(1, 'quarter').endOf('quarter');

        this.selectedMonth = toDate.get('month');
        this.setMonthDisabled(true, [toDate.format('MMMM')]);

        this.defaultOnFilterSelect(params);
        this.defaultSelectedDate.value = `${this.moment()
          .subtract(1, 'quarter')
          .startOf('quarter')
          .format(this.dateFormat)}
          to ${toDate.format(this.dateFormat)}`;
        this.onDateRangeChangeEmiter([
          this.moment().subtract(1, 'quarter').startOf('quarter').format(this.dateFormat),
          toDate.format(this.dateFormat),
        ]);
        this.currentYear = toDate.get('year');
        break;
      case 'PREVIOUS YEAR':
        toDate = this.moment().add(-1, 'year').endOf('year');

        this.selectedMonth = toDate.get('month');
        this.setMonthDisabled(true, [toDate.format('MMMM')]);

        this.selectedFilter = 'Previous Year';
        this.defaultOnFilterSelect(params);
        this.defaultSelectedDate.value = `${this.moment().add(-1, 'year').startOf('year').format(this.dateFormat)}
          to ${toDate.format(this.dateFormat)}`;
        this.onDateRangeChangeEmiter([
          this.moment().add(-1, 'year').startOf('year').format(this.dateFormat),
          toDate.format(this.dateFormat),
        ]);
        this.currentYear = toDate.get('year');
        break;
      case 'PROGRAM TO DATE':
        toDate = this.moment();
        this.selectedFilter = 'Program to Date';

        this.selectedMonth = toDate.get('month');
        this.setMonthDisabled(true, [toDate.format('MMMM')]);

        this.defaultOnFilterSelect(params);
        this.defaultSelectedDate.value = `${this.moment(this.programDate).format(this.dateFormat)}
          to ${toDate.format(this.dateFormat)}`;
        this.onDateRangeChangeEmiter([
          this.moment(this.programDate).format(this.dateFormat),
          toDate.format(this.dateFormat),
        ]);
        this.currentYear = toDate.get('year');
        break;
      case 'CUSTOM RANGE':
        // const value = params.value(this.selectionMode === 'single' ? this.value : this.value[0]);
        // this.customRange = {value: value};
        this.selectedFilter = 'Custom Range';
        this.selectionMode = 'range';
        this.numberOfMonths = 2;
        this.view = 'date';
        // this.defaultOnFilterSelect(params);

        let _date: any = ' to ';
        let to = this.moment(new Date());
        let from = this.moment(new Date());
        _date = this.defaultSelectedDate.value.split('to');
        const d1 = _date[0].trim();
        const d2 = _date[1].trim();
        to = this.moment(d1, this.dateFormat).toDate() || new Date();
        from = this.moment(d2, this.dateFormat).toDate() || new Date();

        this.dateRanges[0] = new Date(to);
        this.dateRanges[1] = new Date(from);

        this.defaultSelectedDate.value = `${this.moment(to).format(this.dateFormat)}
        to ${this.moment(from).format(this.dateFormat)}`;

        this.onDateRangeChangeEmiter([
          this.moment(to).format(this.dateFormat),
          this.moment(from).format(this.dateFormat),
        ]);

        this.defaultSelectedDate.key = params.key;
        this.onIdentifierChange.emit(this.selectedFilter);
        this.updateUI();
        break;
    }
  }

  format(date: any) {
    return this.moment(date).format(this.dateFormat);
  }
  previousDate(value: any) {
    const date = `${value.year}-${value.month + 1}-${value.day}`;
    const selectedDate = Object.assign({}, this.selectDateRange);
    const startDate = this.moment(selectedDate.value[0]._d).format(this.dateFormat);
    const endDate = this.moment(selectedDate.value[1]._d).format(this.dateFormat);
    const range = this.moment(date).isBetween(startDate, endDate);
    if (range) {
      value.range = range;
    }
  }
  ngOnInit() {
    localStorage.removeItem('date_identifier');
    super.ngOnInit();
    // this.selectDateRange = Object.assign({}, this.defaultSelectedDate);
    // this.onDateRangeChangeEmiter([
    //   this.moment(this.defaultSelectedDate.value()[0]._d).format(this.dateFormat),
    //   this.moment(this.defaultSelectedDate.value()[1]._d).format(this.dateFormat)
    // ]);
    // this.done.emit( { date: [
    //   this.moment(this.defaultSelectedDate.value()[0]._d).format(this.dateFormat),
    //   this.moment(this.defaultSelectedDate.value()[1]._d).format(this.dateFormat)
    // ], close: true });
    this.maxDate = new Date();
    if (this.currentCalendarDate.length > 1) {
      this.selectedMonth = this.moment(this.currentCalendarDate[0]).get('month');
    }
    this.rangeLabel = this.defaultSelectedDate.key;
    this.selectedIndex = this.dateDropDown.findIndex((x: any) => x.key === this.defaultSelectedDate.key);
    // this.defaultSelectedDate.value = `${this.moment(this.defaultSelectedDate.value()[0]._d).format(
    //   this.dateFormat
    // )} to ${this.moment(this.defaultSelectedDate.value()[1]._d).format(this.dateFormat)}`;
    const date = this.defaultDate || new Date();
    this.currentMonth = date.getMonth();
    this.currentYear = date.getFullYear();
    if (this.yearNavigator && this.yearRange) {
      const years = this.yearRange.split(':');
      // tslint:disable-next-line:radix
      const yearStart = parseInt(years[0]);
      // tslint:disable-next-line:radix
      const yearEnd = parseInt(years[1]);

      this.populateYearOptions(yearStart, yearEnd);
    }

    if (this.view === 'date') {
      this.createWeekDays();
      this.initTime(date);
      this.createMonths(this.currentMonth, this.currentYear);
      this.ticksTo1970 =
        ((1970 - 1) * 365 + Math.floor(1970 / 4) - Math.floor(1970 / 100) + Math.floor(1970 / 400)) *
        24 *
        60 *
        60 *
        10000000;
    } else if (this.view === 'month') {
      // this.createMonthPickerValues();
    }

    this.doneButton = this.translateService.instant('done_button.title');
  }
  cancel() {
    this.hideDatePicker.emit(false);
  }
  apply() {
    // tslint:disable-next-line:max-line-length
    const one = `${this.inputFieldValue.split('-')[0]}${this.timePicker[0].calendar1.hour}:${
      this.timePicker[0].calendar1.minute
    } ${this.timePicker[0].calendar1.zone}`;
    // tslint:disable-next-line:max-line-length
    const two = `${this.inputFieldValue.split('-')[1]} ${this.timePicker[0].calendar2.hour}:${
      this.timePicker[0].calendar2.minute
    } ${this.timePicker[0].calendar2.zone}`;
    const isDateValid = `${one} - ${two}`.search('undefined');
    if (isDateValid !== -1) {
      this.toaster.info('Please select end date');
      return;
    }
    const CurrentDate = new Date();
    const startGivenDate = new Date(one);
    const endGivenDate = new Date(two);

    if (startGivenDate > CurrentDate) {
      this.toaster.info('Start date is greater than the current date.');
      return;
    }
    if (endGivenDate > CurrentDate) {
      this.toaster.info('End date is greater than the current date.');
      return;
    }
    this.customResult = `${one} - ${two}`;
    // tslint:disable-next-line:max-line-length
    this.hideDatePicker.emit({
      type: 'customRange',
      startDate: new Date(one),
      endDate: new Date(two),
      range: this.customResult,
    });
  }
  selectDropDown(type: any, data: any, i: any) {
    if (i === 0) {
      if (type === 'hour') {
        this.timePicker[0].calendar1.hour = data;
      }
      if (type === 'minute') {
        this.timePicker[0].calendar1.minute = data;
      }
      if (type === 'zone') {
        this.timePicker[0].calendar1.zone = data;
      }
    }
    if (i === 1) {
      if (type === 'hour') {
        this.timePicker[0].calendar2.hour = data;
      }
      if (type === 'minute') {
        this.timePicker[0].calendar2.minute = data;
      }
      if (type === 'zone') {
        this.timePicker[0].calendar2.zone = data;
      }
    }
  }

  calendarMenu(event: Object) {
    if (event && event['value'] === true) {
      this.activeCalendar = false;
    }
  }

  ngAfterViewChecked() {
    // your code to update the model
    this.cd.detectChanges();
  }

  showDateRange_(event: any) {
    this.showDatePicker = !this.showDatePicker;
    this.selectionMode = 'range';
    this.updateUI();
    this.onDataPickerOpen.emit();
    // setTimeout(() => {
    //     this.refViewChild.nativeElement.click();
    // }, 300);

    // if (this.overlayVisible) {
    //   setTimeout(() => {
    //       this.onInputClick(event);
    //     }, 100);
    // }

    // this.onInputClick();
  }
  onDateRangeChangeEmiter(dates: any[]) {
    this._onDateRangeChange.emit({ dates, period: this.selectedFilter });
  }

  ngOnChanges(change: SimpleChanges) {
    if (change.showDatePicker) {
      this.showDatePicker = change.showDatePicker.currentValue;
      if (change.currentCalendarDate) {
        if (change.currentCalendarDate.currentValue.length > 1) {
          this.currentCalendarDate = change.currentCalendarDate.currentValue;
          if (this.identifier == 'MTD') {
            const findIndex = this.dateDropDown.findIndex((dateMode: any) => {
              return dateMode.key == 'MTD';
            });
            const dateMode = this.dateDropDown.find((dateMode: any) => {
              return dateMode.key == 'MTD';
            });
            this.selectCustomDate(dateMode, findIndex);
          } else {
            const start = this.currentCalendarDate[0];
            const end = this.currentCalendarDate[1];
            this.defaultSelectedDate.value = `${this.moment(start).format(this.dateFormat)}
            to ${this.moment(end).format(this.dateFormat)}`;
            this.onDateRangeChangeEmiter([
              this.moment(start).format(this.dateFormat),
              this.moment(end).format(this.dateFormat),
            ]);
          }
        }
      } else {
        let date: any = ' to ';
        let start = this.moment(new Date());
        let end = this.moment(new Date());
        if (this.currentCalendarDate !== undefined) {
          if (this.currentCalendarDate.date !== undefined) {
            date = this.currentCalendarDate.date.split('to');
            if (date.length > 1) {
              const d1 = date[0].trim();
              const d2 = date[1].trim();
              start = this.moment(d1, this.dateFormat).toDate() || new Date();
              end = this.moment(d2, this.dateFormat).toDate() || new Date();
            }
          } else if (this.currentCalendarDate.length > 0) {
            date = this.currentCalendarDate;
            if (date.length > 1 && typeof date[0] === 'string') {
              start = this.moment(date[0].trim(), this.dateFormat).toDate() || new Date();
              end = this.moment(date[1].trim(), this.dateFormat).toDate() || new Date();
            } else if (date.length > 1 && typeof date[0] === 'object') {
              start = date[0];
              end = date[1];
            }
          }
        }
        this.defaultSelectedDate.value = `${this.moment(start).format(this.dateFormat)}
        to ${this.moment(end).format(this.dateFormat)}`;
        this.onDateRangeChangeEmiter([
          this.moment(start).format(this.dateFormat),
          this.moment(end).format(this.dateFormat),
        ]);
      }
    }
  }
  onDatePickerClick(e: any) {}
  isMonthSelected(month: number): boolean {
    // return (this.value && this.value[0])
    // ? (this.value[0].getMonth() === month
    // && this.value[0].getFullYear() === this.currentYear)
    // : false;

    return month === this.selectedMonth;
  }

  shouldAllowMonthSelect() {
    let valid = false;
    const filter = this.selectedFilter.toUpperCase();
    // tslint:disable-next-line:max-line-length
    valid =
      filter === 'Program to Date'.toUpperCase() ||
      filter === 'Previous Quarter'.toUpperCase() ||
      filter === 'Previous Month'.toUpperCase();
    // this.selectedFilter === 'R10' ||
    // this.selectedFilter === 'R1' ||
    // this.selectedFilter === 'R30' ||
    // this.selectedFilter === 'MTD';
    return valid;
  }

  onMonthSelect(event: any, index: number) {
    if (!this.allowMonthSelect) {
      return false;
    }
    if (this.shouldAllowMonthSelect()) {
      return;
    }
    if (this.moment([this.currentYear, index]).isBefore(this.moment(this.maxDate))) {
      this.selectedMonth = index;
      // this.onDateSelect(event, { year: this.currentYear, month: index, day: 1, selectable: true });
      // this.selectRange(this.moment([this.currentYear, index]).startOf('month').format(this.dateFormat));
      const month = moment().get('month');
      if (month === index) {
        this.selectedMonth = index;
        this.onDateSelect(event, { year: this.currentYear, month: index, day: 1, selectable: true });
        this.selectRange(this.moment([this.currentYear, index]).startOf('month').format(this.dateFormat));
      } else {
        this.rangeLabel = this.rangeLabel || localStorage.getItem('gbf_date_identifier');
        if (this.rangeLabel === 'MTD') {
          this.selectedMonth = index;
          this.onDateSelect(event, { year: this.currentYear, month: index, day: 1, selectable: true });
          this.selectedFilter = 'MTD';
          this.defaultSelectedDate.value = `${this.moment([this.currentYear, index])
            .startOf('month')
            .format(this.dateFormat)}
          to ${this.moment([this.currentYear, index]).endOf('month').format(this.dateFormat)}`;
          this._onDateRangeChange.emit([
            this.moment([this.currentYear, index]).startOf('month').format(this.dateFormat),
            this.moment([this.currentYear, index]).endOf('month').format(this.dateFormat),
          ]);
        } else if (this.rangeLabel === 'R10') {
          this.selectedMonth = index;
          this.onDateSelect(event, { year: this.currentYear, month: index, day: 1, selectable: true });
          this.selectedFilter = 'R10';
          this.defaultSelectedDate.value = `${this.moment([this.currentYear, index])
            .startOf('month')
            .subtract(10, 'days')
            .format(this.dateFormat)}
          to ${this.moment([this.currentYear, index]).startOf('month').format(this.dateFormat)}`;
          this._onDateRangeChange.emit([
            this.moment([this.currentYear, index]).startOf('month').subtract(10, 'days').format(this.dateFormat),
            this.moment([this.currentYear, index]).startOf('month').format(this.dateFormat),
          ]);
        } else if (this.rangeLabel === 'M36') {
          this.selectedMonth = index;
          this.onDateSelect(event, { year: this.currentYear, month: index, day: 1, selectable: true });
          this.selectedFilter = 'M36';
          this.defaultSelectedDate.value = `${this.moment([this.currentYear, index])
            .startOf('month')
            .subtract(-36, 'month')
            .format(this.dateFormat)}
          to ${this.moment([this.currentYear, index]).startOf('month').format(this.dateFormat)}`;
          this._onDateRangeChange.emit([
            this.moment([this.currentYear, index]).startOf('month').subtract(-36, 'month').format(this.dateFormat),
            this.moment([this.currentYear, index]).startOf('month').format(this.dateFormat),
          ]);
        } else if (this.rangeLabel === 'R30') {
          this.selectedMonth = index;
          this.onDateSelect(event, { year: this.currentYear, month: index, day: 1, selectable: true });
          this.selectedFilter = 'R30';
          this.defaultSelectedDate.value = `${this.moment([this.currentYear, index])
            .startOf('month')
            .subtract(30, 'days')
            .format(this.dateFormat)}
          to ${this.moment([this.currentYear, index]).startOf('month').format(this.dateFormat)}`;
          this._onDateRangeChange.emit([
            this.moment([this.currentYear, index]).startOf('month').subtract(30, 'days').format(this.dateFormat),
            this.moment([this.currentYear, index]).startOf('month').format(this.dateFormat),
          ]);
        } else if (this.rangeLabel === 'R90') {
          this.selectedMonth = index;
          this.onDateSelect(event, { year: this.currentYear, month: index, day: 1, selectable: true });
          this.selectedFilter = 'R90';
          this.defaultSelectedDate.value = `${this.moment([this.currentYear, index])
            .startOf('month')
            .subtract(90, 'days')
            .format(this.dateFormat)}
          to ${this.moment([this.currentYear, index]).startOf('month').format(this.dateFormat)}`;
          this._onDateRangeChange.emit([
            this.moment([this.currentYear, index]).startOf('month').subtract(90, 'days').format(this.dateFormat),
            this.moment([this.currentYear, index]).startOf('month').format(this.dateFormat),
          ]);
        } else if (this.rangeLabel === '3MTD') {
          this.selectedMonth = index;
          this.onDateSelect(event, { year: this.currentYear, month: index, day: 1, selectable: true });
          this.selectedFilter = '3MTD';
          this.defaultSelectedDate.value = `${this.moment([this.currentYear, index])
            .startOf('month')
            .subtract(3, 'months')
            .format(this.dateFormat)}
          to ${this.moment([this.currentYear, index]).startOf('month').format(this.dateFormat)}`;
          this._onDateRangeChange.emit([
            this.moment([this.currentYear, index]).startOf('month').subtract(3, 'months').format(this.dateFormat),
            this.moment([this.currentYear, index]).startOf('month').format(this.dateFormat),
          ]);
        } else if (this.rangeLabel === '6MTD') {
          this.selectedMonth = index;
          this.onDateSelect(event, { year: this.currentYear, month: index, day: 1, selectable: true });
          this.selectedFilter = '6MTD';
          this.defaultSelectedDate.value = `${this.moment([this.currentYear, index])
            .startOf('month')
            .subtract(6, 'months')
            .format(this.dateFormat)}
          to ${this.moment([this.currentYear, index]).startOf('month').format(this.dateFormat)}`;
          this._onDateRangeChange.emit([
            this.moment([this.currentYear, index]).startOf('month').subtract(6, 'months').format(this.dateFormat),
            this.moment([this.currentYear, index]).startOf('month').format(this.dateFormat),
          ]);
        } else if (this.rangeLabel === '12MTD') {
          this.selectedMonth = index;
          this.onDateSelect(event, { year: this.currentYear, month: index, day: 1, selectable: true });
          this.selectedFilter = '12MTD';
          this.defaultSelectedDate.value = `${this.moment([this.currentYear, index])
            .startOf('month')
            .subtract(12, 'months')
            .format(this.dateFormat)}
          to ${this.moment([this.currentYear, index]).startOf('month').format(this.dateFormat)}`;
          this._onDateRangeChange.emit([
            this.moment([this.currentYear, index]).startOf('month').subtract(12, 'months').format(this.dateFormat),
            this.moment([this.currentYear, index]).startOf('month').format(this.dateFormat),
          ]);
        } else if (this.rangeLabel === 'QTD') {
          this.selectedMonth = index;
          this.onDateSelect(event, { year: this.currentYear, month: index, day: 1, selectable: true });
          this.selectedFilter = 'QTD';
          // this.moment(event).quarter(this.moment(event).quarter()).startOf('quarter').format(this.dateFormat)
          this.defaultSelectedDate.value = `${this.moment([this.currentYear, index])
            .startOf('quarter')
            .format(this.dateFormat)}
          to ${this.moment([this.currentYear, index]).endOf('month').format(this.dateFormat)}`;
          this._onDateRangeChange.emit([
            this.moment([this.currentYear, index]).startOf('month').startOf('quarter').format(this.dateFormat),
            this.moment([this.currentYear, index]).endOf('month').format(this.dateFormat),
          ]);
        } else if (this.rangeLabel === 'YTD') {
          this.selectedMonth = index;
          this.onDateSelect(event, { year: this.currentYear, month: index, day: 1, selectable: true });
          this.selectedFilter = 'YTD';
          // this.moment(event).quarter(this.moment(event).quarter()).startOf('quarter').format(this.dateFormat)
          this.defaultSelectedDate.value = `${this.moment([this.currentYear, index])
            .startOf('year')
            .format(this.dateFormat)}
          to ${this.moment([this.currentYear, index]).endOf('month').format(this.dateFormat)}`;
          this._onDateRangeChange.emit([
            this.moment([this.currentYear, index]).startOf('year').format(this.dateFormat),
            this.moment([this.currentYear, index]).endOf('month').format(this.dateFormat),
          ]);
        } else if (this.rangeLabel === 'FYTD') {
          this.selectedMonth = index;
          this.onDateSelect(event, { year: this.currentYear, month: index, day: 1, selectable: true });
          this.selectedFilter = 'FYTD';
          let _from =
            moment([this.currentYear, index]).startOf('year').add(3, 'month') < moment([this.currentYear, index])
              ? this.moment([this.currentYear, index]).startOf('year').add(3, 'month').format(this.dateFormat)
              : this.moment([this.currentYear, index])
                  .startOf('year')
                  .subtract(1, 'year')
                  .add(3, 'month')
                  .format(this.dateFormat);
          // this.moment(event).quarter(this.moment(event).quarter()).startOf('quarter').format(this.dateFormat)
          this.defaultSelectedDate.value = `${_from}
          to ${this.moment([this.currentYear, index]).endOf('month').format(this.dateFormat)}`;
          this._onDateRangeChange.emit([
            _from,
            this.moment([this.currentYear, index]).endOf('month').format(this.dateFormat),
          ]);
        } else if (this.rangeLabel === 'PREVIOUS YEAR') {
          this.selectedMonth = index;
          const pYear = this.moment().add(-1, 'year').endOf('year').get('year');
          this.onDateSelect(event, { year: pYear, month: index, day: 1, selectable: true });
          this.selectedFilter = 'PREVIOUS YEAR';
          // this.moment(event).quarter(this.moment(event).quarter()).startOf('quarter').format(this.dateFormat)
          this.defaultSelectedDate.value = `${this.moment([pYear, index]).startOf('year').format(this.dateFormat)}
          to ${this.moment([pYear, index]).endOf('month').format(this.dateFormat)}`;
          this._onDateRangeChange.emit([
            this.moment([pYear, index]).startOf('year').format(this.dateFormat),
            this.moment([pYear, index]).endOf('month').format(this.dateFormat),
          ]);
        }
        //  else if (this.rangeLabel === 'PROGRAM TO DATE') {
        //   this.selectedMonth = index;
        //   this.onDateSelect(event, { year: this.currentYear, month: index, day: 1, selectable: true });
        //   this.selectedFilter = 'PROGRAM TO DATE';
        //   // this.moment(event).quarter(this.moment(event).quarter()).startOf('quarter').format(this.dateFormat)
        //   this.defaultSelectedDate.value = `${this.moment(this.programDate).format(this.dateFormat)}
        //   to ${this.moment([this.currentYear, index]).endOf('month').format(this.dateFormat)}`;
        //   this._onDateRangeChange.emit([
        //     this.moment(this.programDate).format(this.dateFormat),
        //     this.moment([this.currentYear, index]).endOf('month').format(this.dateFormat),
        //   ]);
        // }
      }
    }
  }

  defaultPTDDate() {
    //Default Date Init
    this.selectedIndex = this.dateDropDown.findIndex((x: any) => x.key === 'PROGRAM TO DATE');
    this.params = this.dateDropDown.find((x: any) => x.key === 'PROGRAM TO DATE');
    this.activeCalendar = false;
    this.rangeLabel = this.params.key;
    localStorage.setItem('date_identifier', this.params.key);
    let toDate = moment();

    this.selectedFilter = 'Program to Date';

    this.selectedMonth = toDate.get('month');
    this.setMonthDisabled(true, [toDate.format('MMMM')]);

    this.defaultOnFilterSelect(this.params);
    this.defaultSelectedDate.value = `${this.moment(this.programDate).format(this.dateFormat)}
          to ${toDate.format(this.dateFormat)}`;
    this.onDateRangeChangeEmiter([
      this.moment(this.programDate).format(this.dateFormat),
      toDate.format(this.dateFormat),
    ]);

    let dtDone: HTMLElement = this.dateDone.nativeElement;
    dtDone.click();
  }

  parseDate() {
    if (this.rangeLabel === 'Custom Range') {
      this.onDateRangeChangeEmiter([
        this.value && this.value[0]
          ? this.moment(this.value[0]).format(this.dateFormat)
          : this.moment().format(this.dateFormat),
        this.value && this.value[1]
          ? this.moment(this.value[1]).format(this.dateFormat)
          : this.moment().format(this.dateFormat),
      ]);
    }
    return `${
      this.value && this.value[0]
        ? this.moment(this.value[0]).format(this.dateFormat)
        : this.moment().format(this.dateFormat)
    }
      to ${
        this.value && this.value[1]
          ? this.moment(this.value[1]).format(this.dateFormat)
          : this.moment().format(this.dateFormat)
      }`;
  }

  selectRange(event: any) {
    // this.rangeLabel = params.label;
    switch (this.rangeLabel) {
      case 'R1':
        this.selectedFilter = 'R1';
        // this.defaultOnFilterSelect(params);
        this.defaultSelectedDate.value = `${this.moment(event).subtract(1, 'days').format(this.dateFormat)}
         to ${this.moment().format(this.dateFormat)}`;
        this.onDateRangeChangeEmiter([
          this.moment(event).subtract(1, 'days').format(this.dateFormat),
          this.moment().format(this.dateFormat),
        ]);
        break;
      case 'R10':
        this.selectedFilter = 'R10';
        // this.defaultOnFilterSelect(params);
        this.defaultSelectedDate.value = `${this.moment().subtract(10, 'days').format(this.dateFormat)}
         to ${this.moment().format(this.dateFormat)}`;
        this.onDateRangeChangeEmiter([
          this.moment().subtract(10, 'days').format(this.dateFormat),
          this.moment().format(this.dateFormat),
        ]);
        break;
      case 'R30':
        this.selectedFilter = 'R30';
        // this.defaultOnFilterSelect(params);
        this.defaultSelectedDate.value = `${this.moment().subtract(30, 'days').format(this.dateFormat)}
         to ${this.moment().format(this.dateFormat)}`;
        this.onDateRangeChangeEmiter([
          this.moment().subtract(30, 'days').format(this.dateFormat),
          this.moment().format(this.dateFormat),
        ]);
        break;
      case 'R90':
        this.selectedFilter = 'R90';
        // this.defaultOnFilterSelect(params);
        this.defaultSelectedDate.value = `${this.moment().subtract(90, 'days').format(this.dateFormat)}
        to ${this.moment().format(this.dateFormat)}`;
        this.onDateRangeChangeEmiter([
          this.moment().subtract(90, 'days').format(this.dateFormat),
          this.moment().format(this.dateFormat),
        ]);
        break;
      case 'MTD':
        this.selectedFilter = 'MTD';
        // this.defaultOnFilterSelect(params);
        this.defaultSelectedDate.value = `${this.moment(event).startOf('month').format(this.dateFormat)}
        to ${this.moment().format(this.dateFormat)}`;
        this.onDateRangeChangeEmiter([
          this.moment(event).startOf('month').format(this.dateFormat),
          this.moment().format(this.dateFormat),
        ]);
        break;
      case '3MTD':
        this.selectedFilter = '3MTD';
        // this.defaultOnFilterSelect(params);
        this.defaultSelectedDate.value = `${this.moment().subtract(3, 'months').format(this.dateFormat)}
        to ${this.moment().format(this.dateFormat)}`;
        this.onDateRangeChangeEmiter([
          this.moment().subtract(3, 'months').format(this.dateFormat),
          this.moment().format(this.dateFormat),
        ]);
        break;
      case '6MTD':
        this.selectedFilter = '6MTD';
        // this.defaultOnFilterSelect(params);
        this.defaultSelectedDate.value = `${this.moment().subtract(6, 'months').format(this.dateFormat)}
          to ${this.moment().format(this.dateFormat)}`;
        this.onDateRangeChangeEmiter([
          this.moment().subtract(6, 'months').format(this.dateFormat),
          this.moment().format(this.dateFormat),
        ]);
        break;
      case '12MTD':
        this.selectedFilter = '12MTD';
        // this.defaultOnFilterSelect(params);
        this.defaultSelectedDate.value = `${this.moment().subtract(12, 'months').format(this.dateFormat)}
          to ${this.moment().format(this.dateFormat)}`;
        this.onDateRangeChangeEmiter([
          this.moment().subtract(6, 'months').format(this.dateFormat),
          this.moment().format(this.dateFormat),
        ]);
        break;
      case 'QTD':
        this.selectedFilter = 'QTD';
        //  this.defaultOnFilterSelect(params);
        this.defaultSelectedDate.value = `${this.moment(event)
          .quarter(this.moment(event).quarter())
          .startOf('quarter')
          .format(this.dateFormat)}
        to ${
          this.moment(event).get('month') === this.moment().get('month')
            ? this.moment(event).endOf('month').format(this.dateFormat)
            : this.moment(event).endOf('month').format(this.dateFormat)
        }`;

        this.onDateRangeChangeEmiter([
          this.moment(event).quarter(this.moment(event).quarter()).startOf('quarter').format(this.dateFormat),
          this.moment(event).get('month') === this.moment().get('month')
            ? this.moment(event).endOf('month').format(this.dateFormat)
            : this.moment(event).endOf('month').format(this.dateFormat),
        ]);
        break;
      case 'YTD':
        this.selectedFilter = 'YTD';
        //  this.defaultOnFilterSelect(params);
        this.defaultSelectedDate.value = `${this.moment(event).startOf('year').format(this.dateFormat)}
        to ${
          this.moment(event).get('month') === this.moment().get('month')
            ? this.moment(event).endOf('month').format(this.dateFormat)
            : this.moment(event).endOf('month').format(this.dateFormat)
        }`;

        this.onDateRangeChangeEmiter([
          this.moment(event).startOf('year').format(this.dateFormat),
          this.moment(event).get('month') === this.moment().get('month')
            ? this.moment(event).endOf('month').format(this.dateFormat)
            : this.moment(event).endOf('month').format(this.dateFormat),
        ]);
        break;
      case 'Previous Month':
        this.selectedFilter = 'Previous Month';
        //  this.defaultOnFilterSelect(params);
        // tslint:disable-next-line:max-line-length
        this.defaultSelectedDate.value = `${this.moment(new Date())
          .add(-1, 'month')
          .startOf('month')
          .format(this.dateFormat)}
        to ${this.moment(new Date()).add(-1, 'month').endOf('month').format(this.dateFormat)}`;

        this.onDateRangeChangeEmiter([
          this.moment(new Date()).add(-1, 'month').startOf('month').format(this.dateFormat),
          this.moment(new Date()).add(-1, 'month').endOf('month').format(this.dateFormat),
        ]);
        break;
      case 'Program to Date':
        this.selectedFilter = 'Program to Date';
        //  this.defaultOnFilterSelect(params);
        this.defaultSelectedDate.value = `${this.moment(event).add(1, 'month').startOf('month').format(this.dateFormat)}
          to  ${this.moment(event).add(2, 'month').format(this.dateFormat)}`;

        this.onDateRangeChangeEmiter([
          this.moment(event).add(1, 'month').startOf('month').format(this.dateFormat),
          this.moment(event).add(2, 'month').format(this.dateFormat),
        ]);
        break;
      case 'Custom Range':
        this.selectedFilter = 'Custom Range';
        this.defaultSelectedDate.value = `${this.moment(this.value[0]).add(-1, 'month').format(this.dateFormat)}
        to ${this.moment(this.value[1]).format(this.dateFormat)}`;
        this.onDateRangeChangeEmiter([
          this.moment(this.value[0]).add(-1, 'month').format(this.dateFormat),
          this.moment(this.value[1]).format(this.dateFormat),
        ]);
        break;
    }
  }
}
@NgModule({
  imports: [FormsModule, CommonModule, RfButtonModule, RfSharedModule, TranslateModule],
  exports: [RfCalendarComponent, CVPCalendarComponent, RfButtonModule, RfSharedModule, CalendarInnerComponent],
  declarations: [RfCalendarComponent, CVPCalendarComponent, MenuToggleDirective, CalendarInnerComponent],
})
export class RfCalendarModule {}
